import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import PostItem from '../components/PostItem'
import TitlePage from '../components/TitlePage'
import LocalizedLink from '../components/LocalizedLink'
import useTranslations from '../components/useTranslations'
import * as L from '../components/Solution/styled'
import * as S from '../components/ListWrapper/styled'
import DocumentDownload from '../components/LandingPage/documentDownload'

const Blog = ({ data: { allMarkdownRemark } }) => {
  const {
    retmonTitle,
    retmonDescription,
    subline,
    latestPosts,
    allPosts,
  } = useTranslations()

  const postList = allMarkdownRemark.edges

  return (
    <div className="homepage">
      <DocumentDownload />
      <SEO title={`Blog | ${retmonTitle}`} description={retmonDescription} />
      <L.Title>Blog</L.Title>
      <p>{subline}</p>
      <hr style={{ margin: `2rem 0` }} />
      <h2>
        <strong>{latestPosts}</strong>
      </h2>
      <br />
      <S.ListWrapper>
        {postList.map(
          ({
            node: {
              frontmatter: {
                background,
                category,
                date,
                description,
                title,
                imageURL,
                page,
              },
              excerpt,
              timeToRead,
              fields: { slug },
            },
          }) => (
            <PostItem
              slug={`/blog/${slug}`}
              background={background}
              category={category}
              date={date}
              timeToRead={timeToRead}
              title={title}
              description={excerpt}
              imageURL={imageURL}
            />
          )
        )}
      </S.ListWrapper>
      <br />
      <LocalizedLink to={`/blog/`}>{allPosts}</LocalizedLink>
    </div>
  )
}

export default Blog

export const query = graphql`
  query Index($locale: String!) {
    allMarkdownRemark(
      filter: {
        fields: { locale: { eq: $locale } }
        fileAbsolutePath: {regex: "/(blog)\/.*\\.md$/"}
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            category
            background
            page
            imageURL
            date
          }
          excerpt(pruneLength: 250)
          timeToRead
          fields {
            locale
            slug
          }
        }
      }
    }
  }
`
