import styled from 'styled-components';
import media from 'styled-media-query';
import Img from 'gatsby-image';
import LocalizedLink from '../LocalizedLink';

export const PostItemLink = styled(LocalizedLink)`
  text-decoration: none;
  display: block;
  margin-bottom: var(--space);
  ${media.greaterThan('small')`
    margin-bottom: 0;
  `}
`;

export const PostItemWrapper = styled.section`
display:flex;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #d4d4d4;
  border-radius: 0.7rem;
  /* .homepage & {
    flex-direction: row;
  } */
`;

// export const PostItemImg = styled.img`
//   display: block;
// `

export const PostItemImg = styled(Img)`
  width: 12rem;
  height:auto;
`;
export const PostItemImgURL = styled.img`
  width: 12rem;
  height:auto;
`;
export const PostItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: calc(var(--space) * 1.5) var(--space);
  position: relative;
  cursor: pointer;
  filter: contrast(0.5);
  &:hover {
    filter: contrast(1);
    transition: filter 0.3s ease;
  }
`;

export const PostItemTag = styled.span`
  display: inline-block;
  align-items: center;
  background: ${props =>
    props.background ? props.background : 'red'};
  color: #fff;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: calc(var(--space-sm) / 1.5) var(--space-sm);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export const PostItemDate = styled.time`
  font-size: var(--infoText);
  color: var(--info);
`;

export const PostItemTitle = styled.h2`
  font-size: var(--h3);
  font-weight: var(--bold);
  line-height: 140%;
`;

export const PostItemDescription = styled.p`
  margin-top: 0.3rem;
  font-weight:100;
  font-size:var(--textBase);
`;

export const RetmonCTAButton = styled.button`
background: ${props => props.Color || "var(--red)"};
color:white;
padding:9px;
width:10rem;
transition: all .3s ease; 
&:hover{
background: #ec0753;
transition: all .3s ease; 
  }
&:active,
&:focus{
background: var(--redGR);
  } 
`