import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../useTranslations'

import * as S from './styled'

const PostItem = ({
  slug,
  date,
  timeToRead,
  title,
  description,
  imageURL,
}) => {
  const { toRead, toReadMin } = useTranslations()

  return (
    <S.PostItemLink to={slug}>
      <S.PostItemWrapper>
            {/* <S.PostItemImgURL src={imageURL} alt={title} /> */}
        <S.PostItemInfo>
          <S.PostItemDate>
            {date}
            {/* <span className='post-time-read'>{toRead}: {timeToRead} {toReadMin}</span> */}
          </S.PostItemDate>
          <S.PostItemTitle>{title}</S.PostItemTitle>
          <S.PostItemDescription>{description}</S.PostItemDescription>
        </S.PostItemInfo>
      </S.PostItemWrapper>
    </S.PostItemLink>
  )
}

PostItem.propTypes = {
  slug: PropTypes.string.isRequired,
  background: PropTypes.string,
  category: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  timeToRead: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default PostItem
